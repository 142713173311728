import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import layoutStyles from "./layout.module.scss"
import Smiley from "../svgs/smiley.svg";
import Seo from "../components/seo"

export default function Layout({ children, title }) {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
            githubRepoUrl
          }
        }
      }
    `
  )

  return (
    <div className={ [layoutStyles.layout , title].join(' ') }>
      <Seo />
      <div className="noisebar"></div>
      <div className="siteHeader">
        <div className="sitewrapper">
          <Smiley />
          <div className="grid">
            <div className="header-image col-md-1-2 align-right">
              <div
                className="image-container reveal"
                style={{ "--reveal-delay": '0.5s'}}
              >
                <div className="background"></div>
                <div className="treated-image">
                  <img src={'/images/matt-soria_portrait.jpg'} alt="Portrait of Matt Soria at Glacier National Park" />
                </div>
              </div>

            </div>

            <div className="header-headline col-md-1-2">
              <div
                className="-inner reveal"
                style={{ "--reveal-delay": '0.75s'}}
              >
                <h1>{data.site.siteMetadata.title}</h1>
                <p>Front-end development is what I do for <a href="#work">work</a>, but it's not <a href="#about">who I am</a>. I also like to <a href="#experiments">experiment</a>, and listen to <a href="#music">music</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="site-main">
        <div className="sitewrapper">
          {children}
        </div>
      </main>

      <footer className="site-footer">
        <div className="sitewrapper align-center">
          <p><a href={data.site.siteMetadata.githubRepoUrl} target="_blank" rel="noreferrer noopener">Built with love and maté.</a></p>
        </div>
      </footer>
    </div>
  )
}